import { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  ListItemIcon,
  ListItemText,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import {
  Business,
  Search,
  Attachment as AttachmentIcon,
  DisplaySettings as DisplaySettingsIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { useAppContext } from '../AppProvider';

function SearchBar({
  searchQuery,
  setSearchQuery,
  handleSearchClick,
  isProMode,
  handleToggle,
  isSearchMoved,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkType, setLinkType] = useState(null); // State for link type chip
  const [transcriptAvailable, setTranscriptAvailable] = useState(null);
  const [details, setDetails] = useState({})
  const [isCheckingTranscript, setIsCheckingTranscript] = useState(false); // Loading state
  const {
    skipDisambiguation,
    setSkipDisambiguation,
    mode,
    setMode,
    link,
    setLink,
  } = useAppContext();

  // Supported websites array with associated colors
  const supportedWebsites = [
    {
      name: 'YouTube',
      regex: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\//,
      color: 'red',
    },
    { name: 'TikTok', regex: /^(https?:\/\/)?(www\.)?tiktok\.com\//, color: 'black' },
  ];

  const handleToggleSkipDisambiguation = () => {
    setSkipDisambiguation(!skipDisambiguation);
  };

  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Define the minimum number of words required for a valid search
  const MIN_WORD_COUNT = 3;

  // Helper function to count words in the search query
  const countWords = (query) => {
    return query.trim().split(/\s+/).length;
  };

  // Function to extract the video ID from a YouTube URL
  const extractVideoId = (url) => {
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([\w-]{11})/
    );
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  // Function to check if a YouTube video has captions without using the API
  const checkYouTubeCaptions = async (url) => {
    try {
      const response = await fetch(
        `https://backend-word-testing-934923488639.us-central1.run.app/check-youtube-transcript?input_url=${encodeURIComponent(url)}`
      );
      // const response = await fetch(
      //   `http://localhost:5000/check-youtube-transcript?input_url=${encodeURIComponent(url)}`
      // );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setTranscriptAvailable(data.transcript_available);
      setDetails(data.details)
    } catch (error) {
      console.error('Error checking captions:', error);
      setTranscriptAvailable(false); // Assume no captions on error
    }
  };

  // Effect to detect link type based on searchQuery
  useEffect(() => {
    let detectedType = null;

    // Check if searchQuery is a URL
    if (searchQuery !== '') {
      for (const site of supportedWebsites) {
        if (site.regex.test(searchQuery)) {
          detectedType = site;
          break;
        }
      }

      if (detectedType) {
        setLinkType(detectedType);
        // setMode('extractFacts'); // Set mode to extractFacts for URLs
      } else if (/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?/.test(searchQuery)) {
        setLinkType({ name: 'Unsupported Link', color: 'gray' });
        // setMode('extractFacts'); // Unsupported URLs also set to extractFacts
      } else if (searchQuery.length > 250) {
        setLinkType(null); // No specific link type
        // setMode('extractFacts'); // Set mode to extractFacts for long queries
      } else {
        setLinkType(null); // Reset linkType for non-URLs
        // setMode('verify'); // Set mode to verify for non-URLs
      }
    } else {
      setLinkType(null); // Reset linkType when searchQuery is empty
      // setMode('verify');
    }
  }, [searchQuery, supportedWebsites, setMode]);

  // Determine if the search query is too short (only for non-YouTube URLs)
  const isYouTubeLink = linkType?.name === 'YouTube';
  const MIN_WORD_COUNT_REQUIRED = !isYouTubeLink;
  const isQueryTooShort =
    MIN_WORD_COUNT_REQUIRED && searchQuery.trim() !== '' && countWords(searchQuery) < MIN_WORD_COUNT;

  const initiateSearch = () => {
    if (
      linkType?.name !== 'Unsupported Link' &&
      transcriptAvailable !== false &&
      !isCheckingTranscript &&
      !isQueryTooShort // Ensure query is not too short (if applicable)
    ) {
      handleSearchClick();
    }
  };

  // Effect to check YouTube captions if applicable
  useEffect(() => {
    const videoId = extractVideoId(searchQuery);
    if (videoId) {
      const fetchCaptions = async () => {
        setIsCheckingTranscript(true);
        await checkYouTubeCaptions(searchQuery);
        setIsCheckingTranscript(false);
      };
      fetchCaptions();
    } else if (!searchQuery || searchQuery.length <= 250) {
      setTranscriptAvailable(null); // Reset transcript availability if not a YouTube URL or long query
    }
  }, [searchQuery]);

  return (
    <Box
      className={`search-bar ${isSearchMoved ? 'search-bottom' : 'search-center'}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        position: 'absolute',
        transition: 'all 0.5s ease',
        backgroundColor: '#FFFFFF',
        borderRadius: '25px',
        padding: '8px 16px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Transcript Availability Message */}
      {transcriptAvailable !== null && (
        <Box
          sx={{
            width: '100%',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: transcriptAvailable ? '#e0f7fa' : '#ffebee',
            color: transcriptAvailable ? '#00695c' : '#c62828',
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          {transcriptAvailable ? (
            <>
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Transcript available for '{details.title.slice(0,30)}...'</Typography>
            </>
          ) : (
            <>
              <CancelIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Transcript unavailable</Typography>
            </>
          )}
        </Box>
      )}

      {/* Loading Indicator while checking transcript */}
      {isCheckingTranscript && (
        <Box
          sx={{
            width: '100%',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={20} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Checking transcript availability...
          </Typography>
        </Box>
      )}

      {/* Inform user about extractFacts mode for long queries */}
      {searchQuery.length > 250 && (
        <Box
          sx={{
            width: '100%',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff3e0',
            color: '#e65100',
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          <Typography variant="body2">
            Long query detected. Processing in 'extractFacts' mode.
          </Typography>
        </Box>
      )}

      {/* Inform user if the query is too short (only for non-YouTube queries) */}
      {isQueryTooShort && (
        <Box
          sx={{
            width: '100%',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffebee',
            color: '#c62828',
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography variant="body2">
            Please enter a proper claim you'd like to fact check.
          </Typography>
        </Box>
      )}

      <TextField
        variant="outlined"
        placeholder="Type your claim here..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={(e) => {
          if (
            e.key === 'Enter' &&
            linkType?.name !== 'Unsupported Link' &&
            transcriptAvailable !== false &&
            !isCheckingTranscript && // Prevent search if transcript is being checked
            !isQueryTooShort // Prevent search if query is too short (if applicable)
          ) {
            initiateSearch();
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ color: '#696969' }}>
                <AttachmentIcon />
              </IconButton>
              <IconButton sx={{ color: '#696969' }} onClick={handleMenuClick}>
                <DisplaySettingsIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {linkType && (
                <Chip
                  label={linkType.name}
                  sx={{
                    mr: 1,
                    backgroundColor: linkType.color,
                    color: linkType.name === 'TikTok' ? 'white' : 'inherit',
                  }}
                  size="small"
                />
              )}
              <Tooltip
                title={
                  transcriptAvailable === false
                    ? 'Cannot search without available transcript.'
                    : isCheckingTranscript
                    ? 'Checking transcript availability...'
                    : isQueryTooShort
                    ? `Please enter at least ${MIN_WORD_COUNT} words.`
                    : ''
                }
                disableHoverListener={
                  transcriptAvailable !== false && !isCheckingTranscript && !isQueryTooShort
                }
              >
                <span> {/* Wrapper to enable Tooltip on disabled button */}
                  <IconButton
                    onClick={initiateSearch}
                    disabled={
                      linkType?.name === 'Unsupported Link' ||
                      mode === 'extractFacts' ||
                      transcriptAvailable === false ||
                      isCheckingTranscript || // Disable when checking transcript
                      isQueryTooShort // Disable when query is too short (if applicable)
                    }
                  >
                    {isCheckingTranscript ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Search
                        sx={{
                          color:
                            linkType?.name === 'Unsupported Link' ||
                            transcriptAvailable === false ||
                            isQueryTooShort
                              ? '#B0B0B0'
                              : '#0066FF',
                        }}
                      />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
          sx: {
            borderRadius: '50px',
            backgroundColor: '#FFFFFF',
            width: { xs: '500px', sm: '600px', md: '900px' }, // Added 'px' for sm breakpoint
          },
        }}
        // Optionally disable the entire TextField while checking transcript
        disabled={isCheckingTranscript}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '8px 16px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText>Pro Mode</ListItemText>
          <Switch checked={isProMode} onChange={handleToggle} />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText>Disambiguate</ListItemText>
          <Switch
            checked={!skipDisambiguation}
            onChange={handleToggleSkipDisambiguation}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default SearchBar;
