import React, { useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  Button,
  Tooltip,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import ExploreIcon from '@mui/icons-material/Explore';
import { Link } from 'react-router-dom';
import { useAppContext } from '../AppProvider';
import ApiIcon from '@mui/icons-material/Api';
import DescriptionIcon from '@mui/icons-material/Description';
import ConversationItem from './conversationComponent';

const Sidebar = ({
  isOpen,
  setIsSearchMoved,
  menuFontSize = '16px',
  conversationFontSize = '14px',
}) => {
  const sidebarWidth = 250;
  const visibleClosedWidth = -20;

  const {
    version,
    setVersion,
    currentConversation,
    conversations,
    setConversations,
    setCurrentConversation,
    queries,
    setQueries,
    ids,
    setIds,
    idHistory,
    setIdHistory,
    setMode,
    setLink,
    errorDisplay, setErrorDisplay, setNewSearch
  } = useAppContext();

  // Calculate the translateX value based on isOpen and visibleClosedWidth
  const translateX = isOpen
    ? '0'
    : `-${sidebarWidth - visibleClosedWidth}px`;

  const sidebarStyle = {
    position: 'fixed', // Use fixed to keep it relative to the viewport
    top: 0,
    left: 0,
    width: `${sidebarWidth}px`,
    height: '100%',
    backgroundColor: '#FFF',
    boxShadow: '1px 0px 1px rgba(0,0,0,0.5)',
    transition: 'transform 0.3s ease',
    transform: `translateX(${translateX})`,
    zIndex: 900,
    borderRadius: isOpen ? '20px' : '0px', // Remove borderRadius when closed
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    overflow: 'hidden', // Ensure no content spills out
  };

  const listStyle = {
    flex: 1,
  };

  const settingsButtonStyle = {
    marginTop: 'auto',
    borderTop: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'center',
  };

  const settingsTextStyle = {
    textAlign: 'center',
  };

  // Define styles for menu buttons and conversation items
  const menuItemStyle = {
    fontSize: menuFontSize,
    fontWeight: 'bold',
    margin: '0px 0',
    display: 'flex',
    alignItems: 'center',
  };

  const conversationItemStyle = {
    fontSize: conversationFontSize,
    paddingLeft: '24px',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    margin: '0px 0',
    padding: '6px 12px',
    borderRadius: '8px',
    '&.Mui-selected': {
      backgroundColor: '#F1F3FE',
      '&:hover': {
        backgroundColor: '#F1F3FE',
      },
    },
  };


  const createConversation = () => {
    setErrorDisplay("")
    setQueries([]); // Reset queries or any other state related to conversations if needed
    setIds([]);
    setCurrentConversation('');
    setIdHistory({});
    setIsSearchMoved(false);
    setNewSearch(true)
  };

  // useEffect(() => {
  //   if (Object.keys(idHistory).length > 0) {
  //     setIds(Object.keys(idHistory));
  //     setQueries(Object.values(idHistory).map((response) => response.input));
  //   }
  // }, [idHistory]);

  const handleConversationSelect = (conversation) => {
    setErrorDisplay("")
    setNewSearch(false)
    if (currentConversation !== conversation.id){
      setQueries([]); // Reset queries or any other state related to conversations if needed
      setIds([]);
      setIdHistory({});
      setCurrentConversation(conversation.id);
    }

  };

  return (
    <div style={sidebarStyle}>
      <br/>
      <br/>
      {/* Discover Button */}
      <List>
        <ListItemButton
          sx={{
            fontSize: menuFontSize,
            // Removed fontWeight: 'bold',
            margin: '2px 0',
            display: 'flex',
            alignItems: 'center',
            color: 'grey.500',
          }}
        >
          <Tooltip title="Discover coming soon!" placement="right">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <ExploreIcon sx={{ mr: 2 }} />
              Discover
            </span>
          </Tooltip>
        </ListItemButton>
      </List>

      <List>
        <ListItemButton
          sx={{
            fontSize: menuFontSize,
            // Removed fontWeight: 'bold',
            margin: '2px 0',
            display: 'flex',
            alignItems: 'center',
            color: 'grey.500',
          }}
        >
          <Tooltip title="Add-in coming soon!" placement="right">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <DescriptionIcon sx={{ mr: 2 }} />
              Word add-in
            </span>
          </Tooltip>
        </ListItemButton>
      </List>

      <List>
        <ListItemButton component={Link} to="/api"  sx={menuItemStyle}>
          <ApiIcon sx={{ mr: 2 }} />
          API
        </ListItemButton>
      </List>

      {/* New Chat Button */}
      <List>
        <ListItemButton onClick={createConversation} sx={menuItemStyle}>
          <ChatIcon sx={{ mr: 2 }} />
          New Chat
        </ListItemButton>
      </List>

      {/* Conversations List */}
      <div style={{ ...listStyle, maxHeight: '300px', overflowY: 'auto' }}>
        <List>
          {conversations
            .slice()
            .reverse()
            .map((conversation) => (
              // <ListItemButton
              //   key={conversation.id}
              //   selected={conversation.id === currentConversation}
              //   onClick={() => handleConversationSelect(conversation)}
              //   sx={conversationItemStyle}
              // >
              //   <ListItemText primary={conversation.query} />
              // </ListItemButton>
              <ConversationItem
                key={conversation.id}
                conversation={conversation}
                currentConversation={currentConversation}
                handleConversationSelect={handleConversationSelect}
                conversationItemStyle={conversationItemStyle}
              />
            ))}
        </List>
      </div>

      {/* Settings Section */}
      <div style={settingsButtonStyle}>
        <List>
          <ListItemButton component={Link} to="/settings" sx={menuItemStyle}>
            <SettingsIcon sx={{ mr: 2 }} />
            <ListItemText primary="Settings" sx={settingsTextStyle} />
          </ListItemButton>
        </List>
      </div>
    </div>
  );
};

export default Sidebar;
