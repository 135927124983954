// ThirdColumn.jsx
import React from 'react';
import { Button, Avatar, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const ThirdColumn = () => {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

    const handleLogin = async () => {
        try {
            await loginWithRedirect();
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isAuthenticated ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        src={user.picture}
                        alt={user.name}
                        sx={{ width: 40, height: 40, borderRadius: '50%', marginRight: 1 }}
                    />
                </Box>
            ) : (
                <Button variant="contained" color="primary" onClick={handleLogin}>
                    Sign In
                </Button>
            )}
        </Box>
    );
};

export default ThirdColumn;
