import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Paper,
    Card,
    CardContent,
    Button,
    Chip,
    TextField,
    Skeleton,
    Grow,
    Collapse,
    IconButton,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { marked } from 'marked';
import { useAppContext } from './AppProvider';
import { createAndCheckTask } from './runasyncqueries';
import ReplayIcon from '@mui/icons-material/Replay';
import { v4 as uuidv4 } from 'uuid';
import VideoParagraphComponent from './videoExpand';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMoreIcon
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Import ExpandLessIcon

// Constants
const ITEMS_PER_PAGE = 5;
const DISAMBIGUATE_URL = 'https://backend-word-testing-934923488639.us-central1.run.app/disambiguate';
const MODIFYQUERY_URL = 'https://backend-word-testing-934923488639.us-central1.run.app/modifyquery';
const DUCKDUCKGO_API = 'https://api.allorigins.win/raw?url=';

// Utility Functions
const getDomain = (url) => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (e) {
        console.error("Invalid URL:", url);
        return '';
    }
};

const fetchDuckDuckGo = async (query) => {
    const searchUrl = `https://duckduckgo.com/html/?q=${encodeURIComponent(query)}`;
    const url = `${DUCKDUCKGO_API}${encodeURIComponent(searchUrl)}`;
    try {
        const response = await fetch(url, { method: 'GET' });
        const html = await response.text();

        const results = [];
        const regex = /<h2[^>]*class="[^"]*result__title[^"]*"[^>]*>.*?<a[^>]+href="([^"]+)"[^>]*>(.*?)<\/a>.*?<\/h2>.*?<a[^>]+class="result__snippet"[^>]*>(.*?)<\/a>/gs;

        const matches = [...html.matchAll(regex)];
        matches.slice(0, 5).forEach(match => {
            const url = match[1];
            const title = match[2].replace(/<[^>]+>/g, ''); // Remove HTML tags
            const snippet = match[3].replace(/<[^>]+>/g, ''); // Remove HTML tags
            results.push({ title, url, snippet });
        });
        return results;
    } catch (error) {
        console.error("Error fetching results from DuckDuckGo:", error);
        return [];
    }
};

// Subcomponents

const ClarificationSection = ({
    clarification,
    options,
    clarificationAnswer,
    onAnswerChange,
    onSubmit,
    onSkip,
}) => (
    <div style={{ marginTop: '20px' }}>
        <Typography variant="subtitle1" fontWeight="bold" textAlign="left">
            Clarification Needed:
        </Typography>
        <Typography variant="body1" marginTop="10px" textAlign="left">
            {clarification}
        </Typography>

        {/* Options as buttons */}
        {options && options.map((option, index) => (
            <Button
                key={index}
                variant="outlined"
                color="primary"
                onClick={() => onSubmit(option)}
                style={{ marginTop: '10px', marginRight: '10px' }}
            >
                {option}
            </Button>
        ))}

        {/* Text input field */}
        <TextField
            label="Your Answer"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={clarificationAnswer}
            onChange={onAnswerChange}
            style={{ marginTop: '10px' }}
        />

        {/* Submit and Skip buttons */}
        <div style={{ marginTop: '10px' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit(clarificationAnswer)}
                style={{ marginRight: '10px' }}
            >
                Submit Clarification
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={onSkip}
            >
                Skip
            </Button>
        </div>
    </div>
);

const SourceCard = ({ step, index }) => {
    const domain = getDomain(step.link);
    const faviconUrl = domain
        ? `https://www.google.com/s2/favicons?domain=${domain}`
        : '';

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 500 + index * 300 } : {})}
        >
            <Card variant="outlined">
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {faviconUrl && (
                            <img
                                src={faviconUrl}
                                alt={`${step.source} logo`}
                                style={{ width: '16px', height: '16px', marginRight: '6px' }}
                            />
                        )}
                        {step.link ? (
                            <Typography variant="body2" color="textSecondary">
                                <a
                                    href={step.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                >
                                    {step.source}
                                </a>
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {step.source}
                            </Typography>
                        )}
                    </div>
                    {step.summary && (
                        <Typography variant="body1" style={{ marginTop: '5px' }}>
                            {step.summary}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Grow>
    );
};

const PaginationControls = ({ currentPage, totalPages, onPrevious, onNext }) => (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
            onClick={onPrevious}
            disabled={currentPage === 1}
            style={{ marginRight: '10px', border: 'none', minWidth: 0, padding: 0 }}
        >
            <ArrowBackIcon />
        </Button>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
            Page {currentPage} of {totalPages}
        </Typography>
        <Button
            onClick={onNext}
            disabled={currentPage === totalPages}
            style={{ marginLeft: '10px', border: 'none', minWidth: 0, padding: 0 }}
        >
            <ArrowForwardIcon />
        </Button>
    </div>
);

// Main Component

const FactCheckDisplay = ({ query, id, process, setDone, expandable = false, output = null, done = false }) => { // Add expandable prop with default value
    // State Variables
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [steps, setSteps] = useState([]);
    const [sortedSteps, setSortedSteps] = useState([]);
    const lastSearchedQueryRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [clarification, setClarification] = useState(null);
    const [clarificationAnswer, setClarificationAnswer] = useState('');
    const [isAwaitingClarification, setIsAwaitingClarification] = useState(false);
    const [factDisplay, setFactDisplay] = useState(query);
    const [options, setOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [expanded, setExpanded] = useState(false); // State for expansion

    const { skipDisambiguation, toggleSkipDisambiguation, version, setVersion, setIds, currentConversation, idHistory, setIdHistory, email } = useAppContext();

    const itemsPerPage = ITEMS_PER_PAGE;
    const totalPages = Math.ceil(sortedSteps.length / itemsPerPage);

    // Handlers for Pagination
    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    // Get current items for pagination
    const currentItems = sortedSteps.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Function to add new steps
    const addMessage = (newSteps) => {
        setSteps(newSteps);
    };

    async function addTaskId(id, taskId) {
        const url = "https://backend-word-testing-934923488639.us-central1.run.app/add_task_id";
        const data = {
            "_id": id,
            "task_id": taskId
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const result = await response.json();
            return result.task_ids;
        } catch (error) {
            console.error("Failed to add task ID:", error);
        }
    }

    async function addCachedTasktodB(id, input, result) {
        
        const url = "https://backend-word-testing-934923488639.us-central1.run.app/add_cache_db";
        result['userEmail'] = email
        const data = {
            "_id": id,
            "input": input,
            'result': result
        };
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const result = await response.json();
            return result.task_ids;
        } catch (error) {
            console.error("Failed to add task ID:", error);
        }
    }

    // handleRedo function
    const handleRedo = () => {
        performSearch(query)
    };

    // Function to perform the final search
    const performSearch = async (finalQuery) => {
        console.log("perform search initiated")
        if (output){
            console.log({output})
            setResult(output);
            setSteps(output['intermediate_steps'])

            if (output['intermediate_steps']) {
                if (output['intermediate_steps'].length === 0) {
                    setSortedSteps([])
                }
            } else {
                setSortedSteps([])
            }
            return
        }
        else if (idHistory[id]) {
            setResult(idHistory[id]);
            setSteps(idHistory[id]['intermediate_steps'])

            if (idHistory[id]['intermediate_steps']) {
                if (idHistory[id]['intermediate_steps'].length === 0) {
                    setSortedSteps([])
                }
            } else {
                setSortedSteps([])
            }

            setDone(finalQuery, idHistory[id].Classification, idHistory[id])
            return; // Exit the function early if we already have the result
        } else {
            // You can add additional logic here if needed
        }

        setLoading(true);
        try {
            const { output, error: taskError } = await createAndCheckTask(
                finalQuery,
                "location",
                new Date().toISOString(),
                email,
                "speaker",
                "",
                version,
                addMessage
            );

            if (taskError) {
                setError("An error occurred while processing the query. Please try again.");
                console.error(taskError);
            } else {
                setResult(output);
                if (output.task_id) {
                    setIds((predIds) => [...predIds, output.task_id]);
                    await addTaskId(currentConversation, output.task_id)
                    setIdHistory((prevHistory) => ({
                        ...prevHistory,
                        [output.task_id]: output
                    }));
                    // Optionally update idHistory here
                } else {
                    const newId = uuidv4(); // Avoid shadowing the 'id' prop
                    setIds((predIds) => [...predIds, newId]);
                    await addTaskId(currentConversation, newId)
                    setIdHistory((prevHistory) => ({
                        ...prevHistory,
                        [newId]: output
                    }));
                    await addCachedTasktodB(newId, query, output)
                    // Optionally update idHistory here
                }
                output['intermediate_steps'] = sortedSteps
                setDone(query, output.Classification, output)
                setError(null);
            }
        } catch (err) {
            setError("An unexpected error occurred.");
            console.error(err);
        } finally {
            setLoading(false);
            setIsAwaitingClarification(false);
        }
    };

    // Function to handle disambiguation
    const handleDisambiguate = async () => {
        setLoading(true);
        try {
            const response = await fetch(DISAMBIGUATE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query }),
            });
            const data = await response.json();
            const { output, options } = data;

            if (output.toLowerCase() === 'no question') {
                performSearch(query);
            } else {
                setClarification(output);
                setOptions(options);
                setIsAwaitingClarification(true);
            }
        } catch (err) {
            setError("An error occurred during disambiguation.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle submission of clarification
    const handleSubmitClarification = async (answer) => {
        setIsAwaitingClarification(false);
        if (!answer.trim()) {
            setError("Please provide an answer to the clarification question.");
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(MODIFYQUERY_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    question: clarification,
                    answer: answer,
                }),
            });
            const data = await response.json();
            const { output } = data;

            if (output.toLowerCase() === 'no question') {
                setError("Failed to modify the query based on the clarification.");
                return;
            }

            setFactDisplay(output);
            performSearch(output);
            setClarification(null);
            setClarificationAnswer('');
        } catch (err) {
            setError("An error occurred while modifying the query.");
            console.error(err);
        }
    };

    // Effect to trigger disambiguation or search when query changes
    useEffect(() => {
        if (!process) {
            return
        }
        if (query && lastSearchedQueryRef.current !== query) {
            lastSearchedQueryRef.current = query;
            // Reset previous state
            setResult(null);
            setSteps([]);
            setError(null);
            setClarification(null);
            setClarificationAnswer('');
            setIsAwaitingClarification(false);
            setFactDisplay(query);
            setCurrentPage(1);
            if (idHistory[id]) {
                performSearch(query);
            } else {
                // Start the disambiguation or search process
                if (skipDisambiguation) {
                    performSearch(query);
                } else {
                    handleDisambiguate();
                }
            }

        }
    }, [query, skipDisambiguation, process]);

    useEffect(() => {
        console.log(sortedSteps)
    }, [sortedSteps])

    const parseAndSortSteps = (steps) => {
        if (!Array.isArray(steps)) return [];

        const relevanceOrder = { high: 1, medium: 2, low: 3 };

        return steps
            .map(step => {
                try {
                    return JSON.parse(step);
                } catch (e) {
                    console.error("Failed to parse step:", step);
                    return null;
                }
            })
            .filter(step => step !== null)
            .sort((a, b) => relevanceOrder[a.relevance] - relevanceOrder[b.relevance]);
    };

    useEffect(() => {
        const sorted = parseAndSortSteps(steps);
        if (sorted.length > 0) {
            setSortedSteps(sorted);
        } else {
            setSortedSteps([]);
        }

        // Optionally handle result.Classification here

    }, [steps, result]);

    // Function to handle expansion toggle
    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Paper
            elevation={0}
            style={{
                padding: '20px',
                width: '80vw',
                maxWidth: '1000px',
                margin: '20px auto',
                overflowY: 'auto',
                backgroundColor: 'transparent',
                color: '#333',
                textAlign: 'left',
            }}
        >
            <div>
                {/* Display Query and Classification with Expand/Collapse Button if expandable */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                    {factDisplay}
                    {result ? (
                        <Chip
                            label={result.Classification}
                            color={
                                result.Classification === 'True'
                                    ? 'success'
                                    : result.Classification === 'False'
                                        ? 'error'
                                        : 'warning'
                            }
                            style={{
                                marginLeft: '10px',
                                backgroundColor:
                                    result.Classification === 'True'
                                        ? '#34C759'
                                        : result.Classification === 'False'
                                            ? '#FF3B30'
                                            : '#FF9500',
                                color: 'white',
                                textTransform: 'uppercase', // Capitalize the label text
                            }}
                        />
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width={80} // Adjust to match the expected width of the Chip
                            height={32} // Adjust to match the expected height of the Chip
                            style={{ marginLeft: '10px', borderRadius: '16px' }} // Rounded corners to mimic Chip
                        />
                    )}
                    </Typography>
                    {expandable && (
                        <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </div>

                {/* Conditionally render the expandable content */}
                {expandable ? (
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {/* All content below the specified Typography */}
                        {/* Clarification Section */}
                        {isAwaitingClarification && clarification && (
                            <ClarificationSection
                                clarification={clarification}
                                options={options}
                                clarificationAnswer={clarificationAnswer}
                                onAnswerChange={(e) => setClarificationAnswer(e.target.value)}
                                onSubmit={handleSubmitClarification}
                                onSkip={() => {
                                    setIsAwaitingClarification(false);
                                    performSearch(query);
                                    setClarification(null);
                                    setClarificationAnswer('');
                                }}
                            />
                        )}

                        {/* Loading Indicator or Result Display */}
                        {loading ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                <Skeleton variant="text" width="90%" style={{ marginBottom: '5px' }} />
                                <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
                            </div>
                        ) : result ? (
                            <Grow in={Boolean(result)}>
                                <div style={{ fontFamily: 'Arial' }}>
                                    <div style={{ marginTop: '10px', textAlign: 'left' }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: marked(result.overall_assessment) }}
                                            style={{ marginTop: '5px', lineHeight: '1.5', textAlign: 'left', fontFamily: 'Arial' }}
                                        />
                                    </div>
                                    {result.disambiguation && (
                                        <Typography>
                                            Let's disambiguate: {result.disambiguation}
                                        </Typography>
                                    )}
                                </div>
                            </Grow>
                        ) : null}

                        {/* Source Summaries */}
                        {sortedSteps.length > 0 ? (
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold" textAlign="left">
                                    {`Source Summaries: (${sortedSteps.length} sources)`}
                                </Typography>
                                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {currentItems.map((step, index) => (
                                        <SourceCard key={index} step={step} index={index} />
                                    ))}
                                </div>

                                {/* Pagination Controls */}
                                {totalPages > 1 && (
                                    <PaginationControls
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPrevious={handlePreviousPage}
                                        onNext={handleNextPage}
                                    />
                                )}
                            </div>
                        ) : (
                            loading && !isAwaitingClarification && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold" textAlign="left">
                                        Source Summaries:
                                    </Typography>
                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {[1, 2, 3, 4, 5].map((index) => (
                                            <Card variant="outlined" key={index}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Skeleton variant="circular" width={16} height={16} style={{ marginRight: '6px' }} />
                                                        <Skeleton variant="text" width={100} />
                                                    </div>
                                                    <Skeleton variant="text" width="90%" style={{ marginTop: '5px' }} />
                                                    <Skeleton variant="text" width="80%" />
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            )
                        )}

                        {/* Error Message */}
                        {error && (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <Typography variant="body1" color="error" style={{ textAlign: 'left' }}>
                                    {error}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginLeft: '10px', padding: '4px 8px' }}
                                    onClick={handleRedo}
                                    startIcon={<ReplayIcon />}
                                >
                                    Retry
                                </Button>
                            </div>
                        )}
                    </Collapse>
                ) : (
                    // Render all content as is without collapse
                    <>
                        {/* Clarification Section */}
                        {isAwaitingClarification && clarification && (
                            <ClarificationSection
                                clarification={clarification}
                                options={options}
                                clarificationAnswer={clarificationAnswer}
                                onAnswerChange={(e) => setClarificationAnswer(e.target.value)}
                                onSubmit={handleSubmitClarification}
                                onSkip={() => {
                                    setIsAwaitingClarification(false);
                                    performSearch(query);
                                    setClarification(null);
                                    setClarificationAnswer('');
                                }}
                            />
                        )}

                        {/* Loading Indicator or Result Display */}
                        {loading ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                <Skeleton variant="text" width="90%" style={{ marginBottom: '5px' }} />
                                <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
                            </div>
                        ) : result ? (
                            <Grow in={Boolean(result)}>
                                <div style={{ fontFamily: 'Arial' }}>
                                    <div style={{ marginTop: '10px', textAlign: 'left' }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: marked(result.overall_assessment) }}
                                            style={{ marginTop: '5px', lineHeight: '1.5', textAlign: 'left', fontFamily: 'Arial' }}
                                        />
                                    </div>
                                    {result.disambiguation && (
                                        <Typography>
                                            Let's disambiguate: {result.disambiguation}
                                        </Typography>
                                    )}
                                </div>
                            </Grow>
                        ) : null}

                        {/* Source Summaries */}
                        {sortedSteps.length > 0 ? (
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant="subtitle1" fontWeight="bold" textAlign="left">
                                    {`Source Summaries: (${sortedSteps.length} sources)`}
                                </Typography>
                                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {currentItems.map((step, index) => (
                                        <SourceCard key={index} step={step} index={index} />
                                    ))}
                                </div>

                                {/* Pagination Controls */}
                                {totalPages > 1 && (
                                    <PaginationControls
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPrevious={handlePreviousPage}
                                        onNext={handleNextPage}
                                    />
                                )}
                            </div>
                        ) : (
                            loading && !isAwaitingClarification && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold" textAlign="left">
                                        Source Summaries:
                                    </Typography>
                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {[1, 2, 3, 4, 5].map((index) => (
                                            <Card variant="outlined" key={index}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Skeleton variant="circular" width={16} height={16} style={{ marginRight: '6px' }} />
                                                        <Skeleton variant="text" width={100} />
                                                    </div>
                                                    <Skeleton variant="text" width="90%" style={{ marginTop: '5px' }} />
                                                    <Skeleton variant="text" width="80%" />
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            )
                        )}

                        {/* Error Message */}
                        {error && (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <Typography variant="body1" color="error" style={{ textAlign: 'left' }}>
                                    {error}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginLeft: '10px', padding: '4px 8px' }}
                                    onClick={handleRedo}
                                    startIcon={<ReplayIcon />}
                                >
                                    Retry
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Paper>
    );
};

export default FactCheckDisplay;
