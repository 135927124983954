// import React, { useState, useEffect  } from 'react';
// import Sidebar from './Sidebar';
// import { Button, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchComponent from './SearchComponent';
// import ThirdColumn from './ThirdColumn';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useAppContext } from '../AppProvider';
// import { useParams } from 'react-router-dom';


// const Home = () => {
//     const [isSidebarOpen, setSidebarOpen] = useState(false);
//     const { isAuthenticated, logout } = useAuth0();
//     const { isSearchMoved, setIsSearchMoved, setCurrentConversation} = useAppContext();
    
    
//     const toggleSidebar = () => {
//         setSidebarOpen(!isSidebarOpen);
//     };
    

//     const { id } = useParams(); // Retrieve the ID from the URL

//     useEffect(() => {
//         console.log(id)
//         setCurrentConversation(id)
//     }, []); // The dependency array; useEffect will run only when 'count' changes

      

//     const columnStyle = {
//         flex: 1,
//         padding: '10px',
//         boxSizing: 'border-box',
//         position: 'relative',
//     };


//     return (
//         <div id="home_parent" style={{ backgroundColor: '#F1F3FE', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <div
//                 id="inner_blue_border_div"
//                 style={{
//                     width: '98%',
//                     height: '98%',
//                     border: '1.5px solid #0066FF',
//                     borderRadius: '20px',
//                     display: 'flex',
//                     position: 'relative',
//                     overflow: 'hidden',
//                     justifyContent: 'space-between',
//                 }}
//             >
//                 {/* First Column - Sidebar */}
                
//                 <div style={{ ...columnStyle, position: 'relative' }}>
//                     <IconButton onClick={toggleSidebar} style={{ position: 'absolute', top: 15, left: 0, zIndex: 1000 }}>
//                         <MenuIcon style = {{color : '#0066FF'}}/>
//                     </IconButton>
//                     {isAuthenticated &&(
//                         <Sidebar isOpen={isSidebarOpen} style={{ marginTop: '20px' }} setIsSearchMoved={setIsSearchMoved}/>
//                     )
//                     }
//                 </div>

//                 {/* Middle Column - Header and Textbox */}
//                <SearchComponent isSearchMoved = {isSearchMoved } setIsSearchMoved = {setIsSearchMoved}></SearchComponent>

//                 {/* Last Column - Sign In Button */}
//                 <ThirdColumn></ThirdColumn>
//             </div>
//         </div>
//     );
// };

// export default Home;


// Home.jsx
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { IconButton, Grid, Box, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchComponent from './SearchComponent';
import ThirdColumn from './ThirdColumn';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../AppProvider';
import { useParams } from 'react-router-dom';

const Home = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const { isAuthenticated } = useAuth0();
    const { isSearchMoved, setIsSearchMoved, setCurrentConversation, setNewSearch } = useAppContext();
    const { id } = useParams(); // Retrieve the ID from the URL

    useEffect(() => {
        console.log({id});
        setCurrentConversation(id);
        setNewSearch(false);
        if (!id){
            setNewSearch(true)
        }
    }, [id]); // Added dependencies for best practices

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <Box sx={{ backgroundColor: '#F1F3FE', width: '100%', height: '100vh' }}>
            <Grid container sx={{ height: '100%' }}>
                {/* Header */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingY: 5,
                        backgroundColor: 'transparent',
                        position: 'relative',
                    }}
                >

                    <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                            <Typography variant="h4" color="#0066FF" gutterBottom>
                                Facticity.AI
                            </Typography>
                            </Grid>
                    </Grid>
                    {isAuthenticated && (
                        <>
                            <IconButton
                                onClick={toggleSidebar}
                                sx={{ position: 'absolute', top: 15, left: 15, zIndex: 1000 }}
                            >
                                <MenuIcon sx={{ color: '#0066FF' }} />
                            </IconButton>
                            <Sidebar isOpen={isSidebarOpen} sx={{ marginTop: '60px' }} setIsSearchMoved={setIsSearchMoved} />
                        </>
                    )}
                    <ThirdColumn />
                </Grid>

                {/* Sidebar and Main Content */}
                <Grid container item xs={12} sx={{ flexGrow: 1 }}>
                    {/* Sidebar */}
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{
                            borderRight: '1.5px solid #0066FF',
                            backgroundColor: '#f9f9f9',
                            position: 'relative',
                            display: { xs: 'none', sm: 'block' }, // Hide sidebar on extra-small screens
                        }}
                    >
                        {/* Sidebar content goes here */}
                    </Grid>

                    {/* Main Content */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: 2,
                            overflowY: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxHeight: '95vh'
                        }}
                    >
                        <SearchComponent isSearchMoved={isSearchMoved} setIsSearchMoved={setIsSearchMoved} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Home;
