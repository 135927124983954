import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Settings from './components/Settings/Settings';
import { useAuth0 } from '@auth0/auth0-react';
import Api from './API';
const App = () => {
  const { user, isAuthenticated }  = useAuth0();

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/c/:id" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/api" element={<Api />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
