import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Create a Context for the Disambiguation
const AppContext = createContext();


// Create a Provider component
export const AppProvider = ({ children }) => {
  const { user, isAuthenticated }  = useAuth0();
  const [skipDisambiguation, setSkipDisambiguation] = useState(true);
  const [version, setVersion] = useState("pro")
  const [isSearchMoved, setIsSearchMoved] = useState(false);
  const [mode, setMode] = useState("verify")
  const [errorDisplay, setErrorDisplay] = useState("")
  const [email, setEmail] = useState(null);

  
  const [ids, setIds] = useState([])
  const [currentConversation, setCurrentConversation] = useState("")
  const [queries, setQueries] = useState([])
  const [conversations, setConversations] = useState([])
  const [link, setLink] = useState("")
  const [NewSearch, setNewSearch] = useState(true)
  
  const [workspaceLoading, setWorkspaceLoading] = useState(false)

  const [idHistory, setIdHistory] = useState({})
  
  useEffect(() => {
    if (isAuthenticated) {
      setEmail(user?.email || "");
    } else {
      setEmail("");
    }
  }, [isAuthenticated, user]);


  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email, // replace with actual email
            id: "" // empty id
          })
        });

        if (!response.ok) {
          console.log("Network response was not ok");
        } else{
            const data = await response.json();
            setConversations(data.ids);
        }


        // setCurrentConversation(data.ids[0]); // Set the first conversation as the current one
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, [email]);

  const fetchTaskStatus = async (conversationId) => {
    // console.log("FETCHING", conversationId)
    try {
      setWorkspaceLoading(true);
      setIsSearchMoved(true);
  
      const response = await fetch('https://backend-word-testing-934923488639.us-central1.run.app/check-multiple-task-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: conversationId }), // assuming the conversationId matches the "_id" expected by the endpoint
      });
    
      console.log({fetchStatus:response})
      if (!response.ok) {
        console.warn(`Backend responded with status: ${response.status}`);
        return; // Exit early if the response is not OK
      }
  
      const data = await response.json();
      console.log({ data });
  
      if (!data.responses || data.responses.length === 0) {
        console.log("No responses found");
        return; // Exit early if there are no responses
      }
  
      const mappedResponses = data.responses.reduce((acc, response) => {
        acc[response.task_id] = response;
        return acc;
      }, {});
  
      setIdHistory(mappedResponses);
      setIds(Object.keys(mappedResponses));
      setQueries(Object.values(mappedResponses).map((response) => response.input));
  
      window.history.replaceState(null, '', `/c/${conversationId}`);
    } catch (error) {
      console.warn('An error occurred while fetching task status:', error);
      // Log the error and silently handle it without updating state
    } finally {
      setWorkspaceLoading(false);
    }
  };
  

//   const fetchTaskStatus = async (conversationId) => {
//     // console.log("FETCHING", conversationId)
//     setWorkspaceLoading(true)
//     setIsSearchMoved(true)
//     try {
//       const response = await fetch('https://backend-word-testing-934923488639.us-central1.run.app/check-multiple-task-status', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ _id: conversationId }), // assuming the conversationId matches the "_id" expected by the endpoint
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log({data})
//         // console.log('Task status response:', data);
//         const mappedResponses = data.responses.reduce((acc, response) => {
//           acc[response.task_id] = response;
//           return acc;
//         }, {});
//         if(data.responses.length === 0){
//             console.log("empty response")
//         }
//         setIdHistory(mappedResponses)
//         setIds(Object.keys(mappedResponses));
//         setQueries(Object.values(mappedResponses).map((response) => response.input));
        
//         window.history.replaceState(null, '', `/c/${conversationId}`);
//         // Here, you can update the state with the response or display it in some other way
//       } else {
//         console.log("Error")
//         setErrorDisplay("Session ended abruptly, no conversation found.")
//         console.error('Failed to fetch task status:', response.statusText);
//       }
//     } catch (error) {
//       console.log("Error")
//       setErrorDisplay("Something went wrong. Please give us some time to figure it out.")
//       console.error('An error occurred:', error);
//     } finally {
//         setWorkspaceLoading(false)
//     }
//   };



  useEffect(() => {
    console.log({ NewSearch });
    if (currentConversation !== undefined && currentConversation !== "" && !NewSearch) {
      console.log("excecuted",NewSearch)
      fetchTaskStatus(currentConversation);

      const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

      let matchingConversation = null;

        for (let i = 0; i < conversations.length; i++) {
            if (conversations[i].id === currentConversation) {
                matchingConversation = conversations[i];
                break; // Stop the loop once we find the match
            }
        }

        if(matchingConversation){
            if (youtubeRegex.test(matchingConversation.query)) {
                setMode('extractFacts');
                setLink(matchingConversation.query)
            } else {
                setMode('verify');
            }
        }
    }

  }, [currentConversation, conversations, NewSearch]);
  

  // Function to toggle skipDisambiguation state
  const toggleSkipDisambiguation = () => {
    setSkipDisambiguation(prev => !prev);
  };

  return (
    <AppContext.Provider value={{ 
        skipDisambiguation, 
        setSkipDisambiguation, 
        toggleSkipDisambiguation,
        version,
        setVersion,
        currentConversation,
        setCurrentConversation,
        queries,
        setQueries,
        ids,
        setIds,
        conversations,
        setConversations,
        idHistory, 
        setIdHistory,
        isSearchMoved, 
        setIsSearchMoved,
        workspaceLoading,
        mode,
        setMode,
        link, setLink, errorDisplay, setErrorDisplay, email, setNewSearch
        }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = () => {
  return useContext(AppContext);
};
