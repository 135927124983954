import React, { useState, useEffect, useRef } from 'react';

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Switch,
  Paper,
  Skeleton,
  Grid
} from "@mui/material";

import { Search, Add } from "@mui/icons-material";
import FactCheckDisplay from '../FactCheckDisplay';
import './animation.css';
import './scrollbar.css'
import { useAppContext } from '../AppProvider';

import FormControlLabel from '@mui/material/FormControlLabel';
import Android12Switch from './Switches/AndroidSwitch';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import SearchBar from './searchBar';
import ErrorComponent from './ErrorComponent';
import { AttachFile, Image, AlternateEmail } from '@mui/icons-material';
import VideoParagraphComponent from '../videoExpand';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

const SearchComponent = ({isSearchMoved, setIsSearchMoved}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isProMode, setIsProMode] = useState(false);
  const { version, setVersion, currentConversation, setConversations, setCurrentConversation, queries, setQueries, email, ids, setIds, workspaceLoading, mode, link, setLink, idHistory, setIdHistory, setMode, errorDisplay, setErrorDisplay, setNewSearch} = useAppContext();

  const scrollableDivRef = useRef(null);

  const handleToggle = (event) => {
    const isChecked = event.target.checked;
    // console.log(isChecked ? "pro" : "basic");
    setIsProMode(isChecked);
    setVersion(isChecked ? "pro" : "basic");
  };

  useEffect(() => {
    setIsProMode(version === "pro");
  }, [version]);

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      const scrollableDiv = scrollableDivRef.current;
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const sendCurrentConversation = async (email, currentConversation, query) => {
    try {
        await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email, // user email
                id: {
                    id: currentConversation, // current conversation ID
                    query: query // conversation query
                }
            })
        });
    } catch (error) {
        console.error("Error sending current conversation:", error);
    }
  };

  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

// const handleSearchClick = async () => {
//   setErrorDisplay("")
//   const trimmedQuery = searchQuery.trim();
  
//   if (trimmedQuery) {
//     // Determine if a new conversation should be started
//     const shouldStartNewConversation =
//       queries.length === 0 || isUrl(trimmedQuery) || mode === 'extractFacts';

//     if (isUrl(trimmedQuery) || mode === 'extractFacts') {
//       // Set link only if the query is a URL
//       if (isUrl(trimmedQuery)) {
//         setLink(trimmedQuery);
//         setQueries([trimmedQuery]);
//         setMode('extractFacts'); 
//       } else {
//         setMode('verify')
//         setQueries([trimmedQuery]);

//       }
//       console.log({trimmedQuery})
      

//     } else {
//       setMode('verify');
//       setQueries((prevQueries) => [...prevQueries, trimmedQuery]);
//     }

//     if (shouldStartNewConversation) {
//       setIdHistory({})
//       const newConversationId = uuidv4();
//       setCurrentConversation(newConversationId);

//       setConversations((prevIds) => [
//         ...prevIds,
//         { query: trimmedQuery, id: newConversationId },
//       ]);

//       // Send conversation data and update URL without page navigation
//       await sendCurrentConversation(email, newConversationId, trimmedQuery);
//       window.history.replaceState(null, "", `/c/${newConversationId}`);
//     }

    
//     // Clear the search input and move the search UI if needed
//     setSearchQuery("");
//     setIsSearchMoved(true);
//     // Ensure new content is rendered before scrolling
//   }
// };

const handleSearchClick = async () => {
  setErrorDisplay("");
  // Clear the search input and move the search UI if needed
  setSearchQuery("");
  setIsSearchMoved(true);
  const trimmedQuery = searchQuery.trim();

  if (!trimmedQuery) return;

  let newMode = mode;
  let newQueries = [...queries];
  console.log({newQueries})
  let newLink = null;

  let newSearchState = false;
  // Determine if a new conversation should be started
  const shouldStartNewConversation =
    queries.length === 0 || isUrl(trimmedQuery) || mode === 'extractFacts';

  if (isUrl(trimmedQuery)) {
    newLink = trimmedQuery;
    newQueries = [trimmedQuery];
    newMode = 'extractFacts';
    setNewSearch(true)
  } else if (mode === 'extractFacts') {
    newMode = 'verify';
    newQueries = [trimmedQuery];
    setNewSearch(true)
  } else {
    newMode = 'verify';
    newQueries.push(trimmedQuery);
  }

  // Update states based on the above decisions
  if (newLink) setLink(newLink);
  setMode(newMode);
  setQueries(newQueries);
  console.log({ trimmedQuery });

  if (shouldStartNewConversation) {
    setIdHistory({});
    const newConversationId = uuidv4();
    setCurrentConversation(newConversationId);

    setConversations((prevIds) => [
      ...prevIds,
      { query: trimmedQuery, id: newConversationId },
    ]);

    // Send conversation data and update URL without page navigation
    await sendCurrentConversation(email, newConversationId, trimmedQuery);
    window.history.replaceState(null, "", `/c/${newConversationId}`);
  }


};


  useEffect(() => {
    console.log({queries})
  }, [queries]);


  // // Scroll to bottom when queries change
  // useEffect(() => {
  //   console.log({queries})
  //   console.log({mode})
  //   if (queries.length > 0) {
  //     scrollToBottom();
  //   }
  // }, [queries]);

  // return (
  //   <Box
  //     sx={{
  //       display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       textAlign: 'center',
  //       padding: '20px',
  //       boxSizing: 'border-box',
  //       position: 'relative',
  //       minHeight: '100vh',
  //     }}
  //   >
  //     <Box display="inline-flex" alignItems="center">
  //       <a href="https://time.com/7094922/ai-seer-facticity-ai/" target="_blank" rel="noopener noreferrer">
  //         <img src="/timesBest.png" alt="Times Best Invention" style={{ height: '4em', marginRight: '10px' }} />
  //       </a>
  //       <Typography variant="h4" color="#0066FF" gutterBottom>
  //         Facticity.AI
  //       </Typography>
  //     </Box>
  //     {workspaceLoading ? (
  //       // Skeleton loading view
  //       <Box sx={{ width: '100%', mt: 4 }}>
  //         <Skeleton variant="text" width="100%" height={60} />
  //         <Skeleton variant="rectangular" width="100%" height={400} sx={{ my: 2 }} />
  //         <Skeleton variant="text" width="100%" height={40} />
  //       </Box>
  //     ) : !queries.length ? (
  //       <div>
  //       {errorDisplay !== "" ? (

  //         <ErrorComponent errorDisplay={errorDisplay}/>

  //       ) : 
  //       <Box className="fade-in">
  //         <br/>
  //         <br/>
  //         <br/>
  //         <br/>
  //         <Typography variant="h2" color="#0066FF" mt={4}>
  //           Let's get fact-checking!
  //         </Typography>
  //       </Box>
  //       }
  //       </div>
  //     ) : (
  //       <div
  //         className="scrollable-container"
  //         style={{
  //           maxHeight: '80vh',
  //           overflowY: 'auto',
  //           scrollBehavior: 'smooth',
  //           paddingBottom: '100px',
  //         }}
  //       >
  //         {mode === 'verify' ? (
  //           <Paper
  //             elevation={0}
  //             className="fact-check-window"
  //             sx={{
  //               width: '100%',
  //               maxWidth: '1100px',
  //               backgroundColor: 'transparent',
  //               border: 'none',
  //               boxShadow: 'none',
  //               borderRadius: '10px',
  //               marginTop: '20px',
  //               margin: 'auto',
  //             }}
  //           >
  //             <div ref={scrollableDivRef}>
  //               {queries.map((query, index) => (
  //                 <FactCheckDisplay 
  //                   key={index} 
  //                   query={query} 
  //                   id={ids[index] ? ids[index] : null} 
  //                   setDone={() => {}} 
  //                   process={true}
  //                 />
  //               ))}
  //             </div>
  //           </Paper>
  //         ) : (
  //           <Paper
  //             elevation={0}
  //             className="fact-check-window"
  //             sx={{
  //               width: '100%',
  //               maxWidth: '1800px',
  //               backgroundColor: 'transparent',
  //               border: 'none',
  //               boxShadow: 'none',
  //               borderRadius: '10px',
  //               marginTop: '20px',
  //               margin: 'auto',
  //             }}
  //           >
  //             <div ref={scrollableDivRef}>
  //             <VideoParagraphComponent
  //               key={0}
  //               id={0}
  //               claim={link}
  //               email={email}
  //               readyin={false}
  //             />

  //             </div>
  //           </Paper>
  //         )}
  //       </div>
  //     )}
  //     <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearchClick={handleSearchClick} isProMode={isProMode} handleToggle={handleToggle} isSearchMoved={isSearchMoved} />
  //   </Box>
  // )};
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: 2,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: 'transparent', // Changed to transparent
      }}
    >

      {/* Main Content */}
      {workspaceLoading ? (
        // Skeleton loading view
        <Box sx={{ width: '100%', mt: 4, px: 2 }}>
          <Skeleton variant="text" width="100%" height={60} />
          <Skeleton variant="rectangular" width={800} height={400} sx={{ my: 2 }} />
          <Skeleton variant="text" width="100%" height={40} />
        </Box>
      ) : !queries.length ? (
        <Box>

        </Box>
      ) : (
        <Box
          className="scrollable-container"
          sx={{
            width: '100%',
            maxHeight: '80vh',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            paddingBottom: '100px',
            mt: 4,
            px: 2,
          }}
        >
          {mode === 'verify' ? (
            <Paper
              elevation={0}
              className="fact-check-window"
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', md: '1100px' },
                backgroundColor: 'transparent', // Ensure Paper is transparent
                border: 'none',
                boxShadow: 'none',
                borderRadius: '10px',
                margin: 'auto',
              }}
            >
              <Box ref={scrollableDivRef}>
                {queries.map((query, index) => (
                  <FactCheckDisplay
                    key={index}
                    query={query}
                    id={ids[index] || null}
                    setDone={() => {}}
                    process
                  />
                ))}
              </Box>
            </Paper>
          ) : (
            <Paper
              elevation={0}
              className="fact-check-window"
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', md: '1800px' },
                backgroundColor: 'transparent', // Ensure Paper is transparent
                border: 'none',
                boxShadow: 'none',
                borderRadius: '10px',
                margin: 'auto',
              }}
            >
              <Box ref={scrollableDivRef}>
                <VideoParagraphComponent
                  key={0}
                  id={0}
                  claim={link}
                  email={email}
                  readyin={false}
                />
              </Box>
            </Paper>
          )}
        </Box>
      )}

      {/* Search Bar */}
      <Box sx={{ width: '100%', px: 2, mt: 2 }}>
        {/* Handle workspace loading */}
        {!workspaceLoading && (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
            }}
          >
            {/* Display when there are no queries */}
            {!queries.length && (
              <>
                {errorDisplay ? (
                  <ErrorComponent errorDisplay={errorDisplay} />
                ) : (
                  <div>
                    <Box display="inline-flex" alignItems="center">
                      <a
                        href="https://time.com/7094922/ai-seer-facticity-ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/timesBest.png"
                          alt="Times Best Invention"
                          style={{ height: '6em', marginRight: '10px' }}
                        />
                      </a>
                    </Box>
                    <Typography variant="h4" color="#0066FF" mt={4}>
                        Let&apos;s get fact-checking!
                    </Typography>
                  </div>

                )}
              </>
            )}
          </Box>
        )}

        {/* Always render the SearchBar */}
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearchClick={handleSearchClick}
          isProMode={isProMode}
          handleToggle={handleToggle}
          isSearchMoved={isSearchMoved}
        />
      </Box>


    </Box>
  );
};

export default SearchComponent;
