import React, { useEffect, useState, useMemo, memo} from 'react';
import { Button, Collapse, Typography, Card, CardContent, Badge } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';

import TextField from '@mui/material/TextField';

import './tooltip.css';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';


import YouTubeEmbed from './video';
// import ExpandComponentUpdated from './clicktoexpand_updated';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getYoutubeVideoDetails } from './getyoutubetitle';
import VideoProcessingLoader from './videoLoading';
import Fab from '@mui/material/Fab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { motion } from 'framer-motion';
import FactCheckDisplay from './FactCheckDisplay';
import { useAppContext } from './AppProvider';


const VideoParagraphComponent = memo(({ id, claim, email, readyin}) => {
  const [expanded, setExpanded] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [value, setValue] = React.useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [assessment, setAssessment] = useState("")
  const [sources, setSources] = useState([])
  const [classification, setClassification] = useState([])
  const [evidence,setEvidence] =useState({})
  const [disambiguation, setDisambiguation] = useState("")
  const [bias, setBias] = useState(null)
  const [ready, setReady] = useState(readyin)
  const [claims, setClaims] = useState([])
  const [count, setCount] = useState({'True':0, 'False':0, 'Inconclusive':0})
  const [total, setTotal] = useState(0)
  const [processedClaims, setProcessedClaims] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultUrl = "https://facticity-backend-a4xncz5jja-as.a.run.app"
  //const defaultUrl = "http://127.0.0.1:8000"
  //const defaultUrl = "https://fastapi-backend-endpoints-934923488639.us-central1.run.app"
  const [title, setTitle] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const [manualTitle, setManualTitle] = useState('');
  const [manualSource, setManualSource] = useState('');
  const [duration, setDuration] = useState(-1)
  const [recievedclaim, setrecievedclaim] = useState(false)
  const [hoveredId, setHoveredId] = useState(null);
  const [filter, setFilter] = useState(null)
  const {queries, ids, idHistory, workspaceLoading} = useAppContext()

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const toggleExpansion = (support) => {
    setExpandedGroups({
      ...expandedGroups,
      [support]: !expandedGroups[support]
    });
  };

  const handleFormExpandClick = () => {
    setShowTextField(true);
  };


  const handlePopupSubmit = () => {
    setTitle(`${manualTitle} by the source: ${manualSource}`);
    setShowPopup(false);
  };


  async function sendErrorLog(query, userEmail, message) {
    try {
      const response = await fetch('https://fbdebate-a4xncz5jja-uc.a.run.app/log_error', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: query,
          userEmail: userEmail,
          message: message
        }),
      });
  
    } catch (error) {
      console.error('Error sending log:', error);
    }
  } 

  async function sendFeedback() {
    console.log(id)
    try {
      const apiUrl = 'https://facticity-backend-a4xncz5jja-as.a.run.app/SendDetailedFeedback';
      const requestBody = { id: { id: id }, response: value };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        console.error('Success');
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const setDone = (claim, classification,output) => {
    setClaims(prevClaims => 
      prevClaims.map(c => 
        c.claim === claim ? { ...c, done: true , classification:classification, output: output} : c
      )
    );
  }


  const addClaims = async (claims, cached) => {
    const newClaims = [];
  
    for (let i = 0; i < claims.length; i++) {
      const newClaim = claims[i];
      const newClaimId = cached ? ids[i] : null;
      const process = cached ? true: false
      const Classification = idHistory[newClaimId] ? idHistory[newClaimId].Classification: null;
      
      newClaims.push(prevClaims => {
        // Check if the claim already exists in the state
        const claimExists = prevClaims.some(claimObj => claimObj.claim === newClaim);
  
        if (!claimExists) {
          const claimObj = {
            claim: newClaim,
            process: process,
            done: cached,
            id: newClaimId,
            classification:Classification,
            output:null
          };
          return [...prevClaims, claimObj];
        }
  
        console.log({ prevClaims });
        return prevClaims;
      });
    }
  
    setClaims(prevClaims => {
      let updatedClaims = [...prevClaims];
  
      for (const addClaim of newClaims) {
        updatedClaims = addClaim(updatedClaims);
      }
  
      return updatedClaims;
    });
  };

  
  function durationToSeconds(duration) {
    const regex = /P(?:([0-9]+)Y)?(?:([0-9]+)M)?(?:([0-9]+)D)?T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+)S)?/;
    const matches = duration.match(regex);
  
    const years = parseInt(matches[1]) || 0;
    const months = parseInt(matches[2]) || 0;
    const days = parseInt(matches[3]) || 0;
    const hours = parseInt(matches[4]) || 0;
    const minutes = parseInt(matches[5]) || 0;
    const seconds = parseInt(matches[6]) || 0;
  
    // Convert all the units to seconds
    const totalSeconds = 
      (years * 31536000) +  // Approximate number of seconds in a year
      (months * 2592000) +  // Approximate number of seconds in a month (30 days)
      (days * 86400) +
      (hours * 3600) +
      (minutes * 60) +
      seconds;
  
    return totalSeconds;
  }
  
  useEffect(() => {
    setrecievedclaim(false)
    console.log({FROM_VIDEO:claim, recieved:recievedclaim})
  }, [claim]);

  useEffect(() => {
    const interval = setInterval(() => {
        const processed = claims.filter(c => c.done).length;
        const nextBatchStart = Math.floor(processed / 10) * 10; // Start new batch every 3 processed claims
        const nextBatchEnd = Math.min(nextBatchStart + 15, claims.length);
  
      setProcessedClaims(processed); // Update the local state
  
      setClaims(prevClaims =>
        prevClaims.map((c, index) =>
          index >= nextBatchStart && index < nextBatchEnd
            ? { ...c, process: true }
            : c
        )
      );
    //   console.log(nextBatchStart, nextBatchEnd) // Uncomment to debug batch starts and ends
    }, 1000); // Check every 1 second
  
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [claims]);


const isInitialRender = React.useRef(true);

useEffect(() => {
  const fetchData = async () => {
    console.log("fetching")
    console.log({queries})
    if (queries.length > 1) {
      addClaims(queries, true);
      setReady(true);
      setrecievedclaim(true);
      return;
    }
    try {
      const apiUrl = `${defaultUrl}/ProcessEverything`;
      console.log(apiUrl);
      const requestBody = {
        query: claim,
        location: "",
        timestamp: "",
        userEmail: email,
        requestID: uuidv4(),
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        setAssessment(
          "Oops! Something went wrong. Please give us a moment to figure it out."
        );
        setrecievedclaim(true);
        await addClaims([], false);
        setReady(true);
        sendErrorLog(claim, email, "claim extraction on video failed");
      }

      const data = await response.json();
      console.log(data);

      if (
        data.error === "The video is longer than 5 minutes. Please choose a shorter video."
      ) {
        setAssessment(
          "The video is longer than 5 minutes. Please choose a shorter video. Ensure the selected video is in English and has captions enabled."
        );
        sendErrorLog(
          claim,
          email,
          "Tried video longer than 5 minutes without premium access"
        );
      } else if (data.status === "bad") {
        setAssessment(
          "Playback failed. Please try another video. Ensure the selected video is in English and has captions enabled."
        );
        sendErrorLog(claim, email, "Tried video without captions");
      }
      setrecievedclaim(true);
      await addClaims(data.claims, false);
      setReady(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setReady(true)
    }
  };


  // if (isInitialRender.current) {
  //   isInitialRender.current = false;
  // } else {
    setClaims([]);
    setCount({ True: 0, False: 0, Inconclusive: 0 });
    fetchData();
  // }
}, [claim, queries]);

  const renderClaims = () => {
    const scrollableContainerStyle = {
      maxHeight: '80vh', // Set your desired maximum height
      overflowY: 'auto',
    };
  
    return (
      <div style={scrollableContainerStyle}>
        {claims
          .filter((claim_i) => !filter || claim_i.classification === filter)
          .map((claim_i, index) => (
            <div key={index}>
              <FactCheckDisplay 
                query={claim_i.claim} 
                id={claim_i.id} 
                process={claim_i.process} 
                setDone={setDone} 
                done = {claim_i.done}
                expandable={true} 
                output={claim_i.output}
              />
            </div>
          ))}
      </div>
    );
  };

  const groupedSources = sources.reduce((acc, source, index) => {
    const support = source.support || 'Unknown';

    if (!acc[support]) {
      acc[support] = [];
    }

    acc[support].push(...source.sources.map(link => link.endsWith(',') ? link.slice(0, -1) : link));

    return acc;
  }, {});

  const classificationCounts = useMemo(() => {
    const counts = claims.reduce(
        (acc, claim) => {
            acc[claim.classification] = (acc[claim.classification] || 0) + 1;
            return acc;
        },
        { True: 0, False: 0, Inconclusive: 0, Unverifiable: 0 }
    );
    
    console.log({claims}); // Log the result
    return counts;
  }, [claims]);

  useEffect(() => {
    const fetchVideoTitle = async () => {
        try {
            if (isValidUrl(claim)) {
                const title = await getYoutubeVideoDetails(claim);
                setTitle(`${title.title} by the Youtube channel: ${title.channel}`);
                var dur = durationToSeconds(title.duration)
                console.log(`${title.title} by the Youtube channel: ${title.channel} +${title.duration}+${dur}`)
                setDuration(dur)
            } else {
                setDuration(60)
                // setShowPopup(true);
            }
        } catch (error) {
            setDuration(60)
            console.error('Error fetching video title:', error);
            // setShowPopup(true);
        }
    };

    fetchVideoTitle();
}, [claim]);


const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (e) {
        return false;
    }
};

const handleChipClick = (filter) => {
  console.log(`Chip clicked: ${filter}`);
  setFilter(filter)
  navigator.clipboard.writeText(claim); 
  // Add your filtering logic here
};


const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  return (
    <Card variant="outlined" style={{ marginBottom: '10px', fontFamily: 'IBM Plex Sans' }}>
    <CardContent>
        <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {isValidYouTubeUrl(claim) ? (
              <YouTubeEmbed videoLink={claim} height={'30vh'} />
          ) : (
              <Typography variant="body1">{claim}</Typography>
          )}
          <Typography>{title}</Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start" p={2}>
              <Chip
                  label={`SHOW ALL (${claims.length})`}
                  style={{ backgroundColor: '#A2AAAD', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleChipClick(null)}
              />
              <Chip
                  label={`TRUE (${classificationCounts.True})`}
                  style={{ backgroundColor: '#34C759', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleChipClick('True')}
              />
              <Chip
                  label={`FALSE (${classificationCounts.False})`}
                  style={{ backgroundColor: '#FF3B30', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleChipClick('False')}
              />
              <Chip
                  label={`UNVERIFIABLE (${classificationCounts.Inconclusive + classificationCounts.Unverifiable})`}
                  style={{ backgroundColor: '#FF9500', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleChipClick('Unverifiable')}
              />
              {(claims.length - classificationCounts.True - classificationCounts.False - classificationCounts.Inconclusive - classificationCounts.Unverifiable > 0) && (
                  <Chip
                      label={`REMAINING (${claims.length - classificationCounts.True - classificationCounts.False - classificationCounts.Inconclusive - classificationCounts.Unverifiable})`}
                      style={{ backgroundColor: '#007AFF', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                      onClick={() => handleChipClick('remaining')}
                  />
              )}
              <Chip
                  label={`COPY INPUT`}
                  style={{ backgroundColor: '#A2AAAD', color: 'white', marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleChipClick('all')}
              />
          </Box>
          
          </Grid>
            <Grid item xs={12} md={8}>
                <Typography variant="body1">
                {ready === false && duration >= 0 && <VideoProcessingLoader videoDuration={duration} done={recievedclaim} claim={claim}/>}
                </Typography>
                <Typography>
                    {assessment}
                </Typography>
                <div>
                    {renderClaims()}
                </div>
            </Grid>
          </Grid>
        
        </CardContent>
    </Card>

//     <Card variant="outlined" style={{ marginBottom: '10px', fontFamily: 'IBM Plex Sans' }}>
//     <div>
//         <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
//             <DialogTitle>Enter Source and Title</DialogTitle>
//             <DialogContent>
//                 <DialogContentText>
//                     Please enter the title and source for the video.
//                 </DialogContentText>
//                 <TextField
//                     autoFocus
//                     margin="dense"
//                     label="Title"
//                     type="text"
//                     fullWidth
//                     value={manualTitle}
//                     onChange={(e) => setManualTitle(e.target.value)}
//                 />
//                 <TextField
//                     margin="dense"
//                     label="Source"
//                     type="text"
//                     fullWidth
//                     value={manualSource}
//                     onChange={(e) => setManualSource(e.target.value)}
//                 />
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={() => setShowPopup(false)}>Cancel</Button>
//                 <Button onClick={handlePopupSubmit}>Submit</Button>
//             </DialogActions>
//         </Dialog>
//     </div>
//     <CardContent>
//         <Grid container spacing={2}>
//             <Grid item xs={12} md={4}>
//                 {isValidYouTubeUrl(claim) ? (
//                     <YouTubeEmbed videoLink={claim} height={'30vh'} />
//                 ) : (
//                     <Typography variant="body1">{claim}</Typography>
//                 )}
//                 <Typography>{title}</Typography>

//                 {workspaceLoading ? (
//                     // Display loading skeletons while workspaceLoading is true
//                     <>
//                         <Skeleton variant="text" width={120} height={40} style={{ marginBottom: '8px' }} />
//                         <Skeleton variant="text" width={80} height={40} style={{ marginBottom: '8px' }} />
//                         <Skeleton variant="text" width={100} height={40} style={{ marginBottom: '8px' }} />
//                         <Skeleton variant="text" width={150} height={40} style={{ marginBottom: '8px' }} />
//                         <Skeleton variant="text" width={130} height={40} style={{ marginBottom: '8px' }} />
//                     </>
//                 ) : (
//                     // Render chips when not loading
//                     <Box display="flex" flexWrap="wrap" justifyContent="flex-start" p={2}>
//                         <Chip
//                             label={`SHOW ALL (${claims.length})`}
//                             style={{ backgroundColor: '#A2AAAD', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                             onClick={() => handleChipClick(null)}
//                         />
//                         <Chip
//                             label={`TRUE (${classificationCounts.True})`}
//                             style={{ backgroundColor: '#34C759', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                             onClick={() => handleChipClick('True')}
//                         />
//                         <Chip
//                             label={`FALSE (${classificationCounts.False})`}
//                             style={{ backgroundColor: '#FF3B30', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                             onClick={() => handleChipClick('False')}
//                         />
//                         <Chip
//                             label={`UNVERIFIABLE (${classificationCounts.Inconclusive + classificationCounts.Unverifiable})`}
//                             style={{ backgroundColor: '#FF9500', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                             onClick={() => handleChipClick('Unverifiable')}
//                         />
//                         {(claims.length - classificationCounts.True - classificationCounts.False - classificationCounts.Inconclusive - classificationCounts.Unverifiable > 0) && (
//                             <Chip
//                                 label={`REMAINING (${claims.length - classificationCounts.True - classificationCounts.False - classificationCounts.Inconclusive - classificationCounts.Unverifiable})`}
//                                 style={{ backgroundColor: '#007AFF', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                                 onClick={() => handleChipClick('remaining')}
//                             />
//                         )}
//                         <Chip
//                             label={`COPY INPUT`}
//                             style={{ backgroundColor: '#A2AAAD', color: 'white', marginRight: '8px', marginBottom: '8px' }}
//                             onClick={() => handleChipClick('all')}
//                         />
//                     </Box>
//                 )}
//             </Grid>

//             <Grid item xs={12} md={8}>
//                 {workspaceLoading ? (
//                     <Skeleton variant="rectangular" height={200} width="100%" />
//                 ) : (
//                     <Typography variant="body1">
//                         {ready === false && duration >= 0 && <VideoProcessingLoader videoDuration={duration} done={recievedclaim} claim={claim} />}
//                     </Typography>
//                 )}
//                 <Typography>{assessment}</Typography>
//                 <div>{!workspaceLoading && renderClaims()}</div>
//             </Grid>
//         </Grid>
//     </CardContent>
// </Card>

  );
});

export default VideoParagraphComponent;
